import React, { Component } from 'react'

class Footer extends Component {

  render() {    
    return (
      <footer className="footer mt-5">
        <div className="container">
          <span className="text-muted">@2020 Anomalist Design, LLC</span>
        </div>
      </footer>
    )
  }
}

export default Footer;