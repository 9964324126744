import React, { Component } from 'react'
import { Link } from 'gatsby';
import { getMenuState } from '../../store/selectors';
import { connect } from 'react-redux';
import logo from "../../assets/img/logo.jpg";

class Header extends Component {

  render() {
    return (
      <nav id="mainNav" className="navbar navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/"><img src={logo} className="logo" /></Link>
        <button className="navbar-toggler navbar-toggler-right pull-right" type="button" data-toggle="collapse" data-target="#MainNavCollapse" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="MainNavCollapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link active" to="/">Home</Link>
            </li>
          </ul>
        </div>  
      </nav>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    menuOpen: getMenuState(state).open,
    nMenuItem: getMenuState(state).nItem,
  }
}

export default connect(mapStateToProps) (Header);
