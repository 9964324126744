import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './Layout.css'
import ResponsiveTopBar from '../ResponsiveTopBar';
import MediaQuery from "react-responsive";
import { default as AntdLayout } from 'antd/lib/layout';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { connect } from 'react-redux';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/main.css";

class Layout extends Component {
  setPostPageState = (state) => {
    this.props.setPostPageState(state)
  }

  render() {
    const {
      children,
    } = this.props

    return (
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => {
          return (
            <MediaQuery
              maxWidth={1000}
            >
              {(matches) => (
                <>
                  <Helmet
                    title={data.site.siteMetadata.title}
                    meta={[
                      { name: 'description', content: "Metanomalies - medical stories that showcase effective medical options you haven't heard of or never seriously considered." },
                      { name: 'keywords', content: "Metanomalies - medical stories that showcase effective medical options you haven't heard of or never seriously considered." },
                      { name: 'image', content: "https://metanomalies.co/assets/img/logo.png" },
                    ]}
                  >
                    <html lang="en" />
                    <link rel="canonical" href="https://getbootstrap.com/docs/4.0/examples/jumbotron/" />
                    <link href="https://use.fontawesome.com/releases/v5.0.4/css/all.css" rel="stylesheet" />
                  </Helmet>
                  <AntdLayout>
                    <AntdLayout.Header>
                      <Row>
                        <Col>
                          <Header siteTitle={data.site.siteMetadata.title} />
                        </Col>
                      </Row>
                    </AntdLayout.Header>
                    <AntdLayout.Content>
                      {children}
                    </AntdLayout.Content>
                    <Footer />
                  </AntdLayout>
                </>)
              }
            </MediaQuery>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = (state) => {
  return {
  }
}

export default connect(mapStateToProps)(Layout);
